import {
  WebGLRenderer,
  sRGBEncoding,
  PerspectiveCamera,
  Scene,
  AmbientLight,
  DirectionalLight,
  MathUtils,
  Vector3,
  LinearFilter,
  RGBAFormat,
  Color,
  VideoTexture,
  Box3,
} from "three";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";
import laptop from "./assets/laptop.glb";
import vidTexture from "./assets/video.mp4";
import { gsap } from "gsap";
import { Fx10 } from "./fx10";


// Section for laptop animation
function laptopAnimation() {
  const canvas = document.querySelector("#laptopModel");
  const renderer = new WebGLRenderer({
    canvas: canvas,
    alpha: true,
    antialias: false,
    powerPreference: "high-performance",
    failIfMajorPerformanceCaveat: true,
  });
  renderer.outputEncoding = sRGBEncoding;
  renderer.setPixelRatio(2);
  renderer.useLegacyLights = true;

  const fov = 36;
  const aspect = canvas.clientWidth / canvas.clientHeight; // the canvas default
  const near = 0.1;
  const far = 100;

  const camera = new PerspectiveCamera(fov, aspect, near, far);
  camera.position.set(0, 0, 8);

  // const controls = new OrbitControls(camera, canvas);
  // controls.target.set(0, 0, 0);
  // controls.update();

  const scene = new Scene();
  scene.background = null;

  {
    const ambientLight = new AmbientLight(0xffffff, 1.2);
    const keyLight = new DirectionalLight(0xffffff, 0.6);
    const fillLight = new DirectionalLight(0xe80368, 0.4);

    fillLight.position.set(10, 1, -12);
    keyLight.position.set(0.5, 0, 0.866);
    const lights = [ambientLight, keyLight, fillLight];
    lights.forEach((light) => scene.add(light));
  }

  function frameArea(sizeToFitOnScreen, boxSize, boxCenter, camera) {
    const halfSizeToFitOnScreen = sizeToFitOnScreen * 0.5;
    const halfFovY = MathUtils.degToRad(camera.fov * 0.5);
    const distance = halfSizeToFitOnScreen / Math.tan(halfFovY);

    const direction = new Vector3()
      .subVectors(camera.position, boxCenter)
      .multiply(new Vector3(1, 0, 1))
      .normalize();

    camera.position.copy(direction.multiplyScalar(distance).add(boxCenter));

    camera.near = boxSize / 100;
    camera.far = boxSize * 100;

    camera.updateProjectionMatrix();

    camera.lookAt(boxCenter.x, boxCenter.y, boxCenter.z);
  }

  const applyScreenTexture = (texture, node) => {
    texture.encoding = sRGBEncoding;
    texture.flipY = false;
    texture.anisotropy = renderer.capabilities.getMaxAnisotropy();
    texture.generateMipmaps = false;
    texture.minFilter = LinearFilter;
    texture.magFilter = LinearFilter;
    texture.format = RGBAFormat;

    renderer.initTexture(texture);

    node.material.color = new Color(0xffffff);
    node.material.transparent = true;
    node.material.map = texture;
  };

  {
    const video = document.createElement("video");
    const videoTexture = new VideoTexture(video);
    video.src = vidTexture;
    video.crossOrigin = "anonymous";
    video.loop = true;
    video.muted = true;
    video.play();

    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("/draco/");

    const gltfLoader = new GLTFLoader();
    gltfLoader.setDRACOLoader(dracoLoader);

    gltfLoader.load(laptop, (gltf) => {
      gltf.scene.traverse((node) => {
        if (node.material) {
          node.material.color = new Color(0x1f1f1f);
          node.material.color.convertSRGBToLinear();
        }

        if (node.name === "Screen") {
          const placeholderScreen = node.clone();
          placeholderScreen.material = node.material.clone();
          node.parent.add(placeholderScreen);
          placeholderScreen.material.opacity = 1;
          placeholderScreen.position.z += 0.001;

          applyScreenTexture(videoTexture, placeholderScreen);
        }
      });

      scene.add(gltf.scene);

      const box = new Box3().setFromObject(gltf.scene);

      const boxSize = box.getSize(new Vector3()).length();
      const boxCenter = box.getCenter(new Vector3());

      frameArea(boxSize, boxSize, boxCenter, camera);

      // controls.maxDistance = boxSize * 10;
      // controls.target.copy(boxCenter);
      // controls.update();

      function onMouseMove(event) {
        gsap.killTweensOf(gltf.rotation);
        const mouseX = (event.clientX / window.innerWidth) * 2 - 1;
        const mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
        const maxRotationX = 7 * (Math.PI / 180);
        const maxRotationY = 14 * (Math.PI / 180);
        const targetRotation = new Vector3(
          MathUtils.clamp(-mouseY * maxRotationX, -maxRotationX, maxRotationX),
          MathUtils.clamp(-mouseX * maxRotationY, -maxRotationY, maxRotationY),
          0
        );
        gsap.to(gltf.scene.rotation, {
          duration: 2.5,
          x: targetRotation.x,
          y: -targetRotation.y,
          ease: "back.out(4)",
          delay: 0.15,
        });
      }
      document.addEventListener("mousemove", onMouseMove);
    });
  }

  function resizeRendererToDisplaySize(renderer) {
    const canvas = renderer.domElement;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      renderer.setSize(width, height, false);
    }
    return needResize;
  }

  function render() {
    if (resizeRendererToDisplaySize(renderer)) {
      const canvas = renderer.domElement;
      camera.aspect = canvas.clientWidth / canvas.clientHeight;
      camera.updateProjectionMatrix();
    }

    renderer.render(scene, camera);

    requestAnimationFrame(render);
  }

  requestAnimationFrame(render);
}

laptopAnimation();

// Section for features card animation
function handleMove(event, card) {
  const height = card.clientHeight;
  const width = card.clientWidth;

  const xVal = event.layerX;
  const yVal = event.layerY;

  const yRotation = 3 * ((xVal - width / 2) / width);
  const xRotation = -3 * ((yVal - height / 2) / height);

  const string =
    "perspective(200px) rotateX(" +
    xRotation +
    "deg) rotateY(" +
    yRotation +
    "deg)";

  card.style.transform = string;
}

function features(card) {
  card.addEventListener("mousemove", (event) => handleMove(event, card));

  card.addEventListener("mouseout", function () {
    card.style.transform = "perspective(200px) rotateX(0) rotateY(0)";
  });
}

let cards = document.querySelectorAll(".transition-card");
for (let i = 0; i < cards.length; i++) {
  features(cards[i]);
}

// Image hover effect
const myDivs = document.querySelectorAll(".image-hover");

for (let i = 0; i < myDivs.length; i++) {
  const fx10 = new Fx10(myDivs[i]);

  myDivs[i].addEventListener("mouseenter", () => {
    fx10.mouseenter();
  });
  myDivs[i].addEventListener("mouseleave", () => {
    fx10.mouseleave();
  });
}
